<script setup>
  import { useStore } from 'vuex'
  const store = useStore()
  const submit = () => {
    store.commit('updateFeedback', true)
  }
</script>

<template>
  <div class="feed-back" @click="submit">
    <img src="../assets/image/message.png" alt="">
    <div>意见反馈</div>
  </div>
</template>

<style scoped lang="less">
  .feed-back {
    // position: fixed;
    height: 80px;
    width: 80px;
    // right: 15px;
    bottom: 30px;
    margin-top: 16px;
    margin-left: 57px;
    background: #FFF;
    color: #6196ff;
    border-radius: 5px;
    z-index: 999;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* 添加阴影效果 */
    img {
      margin-bottom: 2px;
    }
  }
  /* 当鼠标移入或者触摸到按钮时，显示按钮并修改其属性 */
  .feed-back:hover,
  .feed-back:focus {
      // opacity: 1; /* 显示按钮 */
      // transform: scale(1.1); /* 放大按钮 */
      // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* 添加阴影效果 */
  }
</style>